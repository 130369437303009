import moment from 'moment-timezone'

/**
 * convenience function to convert to timezone moment
 * @param {object|Date|string} dateTime
 * @param {string} format
 * @param {string} timezone
 * @returns {object}
 */
function _timezonedMoment ({ dateTime, format, timezone }) {
  return moment.tz(dateTime, format, timezone || moment.tz.guess())
}

export const timezonedMoment = _timezonedMoment
