import _startCase from 'lodash.startcase'
import _cloneDeep from 'lodash.clonedeep'
import _isEqual from 'lodash.isequal'
import moment from 'moment-timezone'
import { C_ADD_SITE_ALERT } from '@/utilities/mutation-types'

import {
  getPromotionTemplate,
  getPromotion,
  getPromotionScripts,
  updatePromotion,
  createPromotion,
  updatePromotionBudget,
  publishPromotion,
  unPublishPromotion,
  archivePromotion
} from '@/services/promotions-service'

import { formatCurrency, formatDate } from '@smarttransit/common'
import { isAuthorized } from '@/utilities/helpers'
import { timezonedMoment } from '@/components/timezone-selector'
let _cachedComponentData = false

export default {
  name: 'promotions-promotion',
  props: {
    signedInUser: Object
  },
  data () {
    return {
      apiInProgress: false,
      publishInProgress: false,
      scriptsInProgress: false,
      archiveInProgress: false,
      currentPromotion: null,
      currentPromotionError: null,
      currentScript: null,
      promotionScripts: [],
      dateStartMenu: null,
      dateEndMenu: null,
      localTimezone: moment.tz.guess(),
      addPhoneContactModal: false,
      phoneContactToAdd: '',
      dateMenuItems: {},
      dayTimeRangeModal: false,
      currentDayTimeRange: null,
      currentDayTimeRangeIndex: null,
      currentDaySet: null,
      currentTimeSet: { start: null, end: null },
      contactsRules: [(a) => {
        if (a && a.length) {
          const nonPhoneNumbers = a.filter(o1 => !/[+0-9()\s-]+/.test(o1))
          if (nonPhoneNumbers.length) {
            const results = nonPhoneNumbers.filter(o1 => !(o1.match(/[^@]+@[^@]+\.[^@]+/i) || []).length)
            if (results.length) {
              return 'Please enter proper email address(es) for: ' + results.join(', ')
            }
          }
        }
        return true
      }, (a) => {
        if (a && a.length) {
          if (a.filter(o => !/\+[0-9()\s-]+/.test(o) && !(o.match(/[^@]+@[^@]+\.[^@]+/i) || []).length).length) {
            return 'Please enter a valid number or use the button above or type in a valid email'
          }
        }
        return true
      }]
    }
  },
  mounted: async function () {
    async function onRouterLoad () {
      if (!this.$route.params.promotionId || this.$route.params.promotionId + '' === '0') {
        this.currentPromotion = getPromotionTemplate()
        this.currentPromotion.draftMetadata.timezone = this.localTimezone
        this.setCurrentPromotion()
      } else {
        this.loadPromotion(this.$route.params.promotionId)
      }
    }
    await this.loadPromotionScripts()
    this.isSuperAdmin = isAuthorized(this.$props.signedInUser, 'superadmin')
    this.$nextTick(onRouterLoad.bind(this))
  },
  computed: {
    computedDateStartFormatted () {
      return this.currentPromotion && this.currentPromotion.dateStartTimezoned ? formatDate(this.currentPromotion.dateStartTimezoned) : ''
    },
    computedDateEndFormatted () {
      return this.currentPromotion && this.currentPromotion.dateEndTimezoned ? formatDate(this.currentPromotion.dateEndTimezoned) : ''
    },
    computedDateStartMax () {
      if (this.currentPromotion) {
        const fromDateAsMoment = this.currentPromotion.dateStartTimezoned ? moment.utc(this.currentPromotion.dateStart, 'Y-MM-DD') : null
        const toDateAsMoment = this.currentPromotion.dateEndTimezoned ? moment.utc(this.currentPromotion.dateEnd, 'Y-MM-DD') : null
        if (toDateAsMoment && (!fromDateAsMoment || fromDateAsMoment.valueOf() <= toDateAsMoment.valueOf())) {
          return toDateAsMoment.subtract(1, 'day').format('Y-MM-DD')
        }
      }
      return ''
    },
    computedDateEndMin () {
      if (this.currentPromotion) {
        const fromDateAsMoment = this.currentPromotion.dateStartTimezoned ? moment.utc(this.currentPromotion.dateStartTimezoned, 'Y-MM-DD') : null
        const toDateAsMoment = this.currentPromotion.dateEndTimezoned ? moment.utc(this.currentPromotion.dateEndTimezoned, 'Y-MM-DD') : null
        if (fromDateAsMoment && (!toDateAsMoment || fromDateAsMoment.valueOf() <= toDateAsMoment.valueOf())) {
          return fromDateAsMoment.add(1, 'day').format('Y-MM-DD')
        }
      }
      return ''
    },
    computedScriptDescription () {
      if (this.promotionScripts && this.promotionScripts.length && this.currentScript) {
        return this.currentScript.description
      }
      return 'Select a script to read its description'
    }
  },
  methods: {
    refreshView () {
      this.$router.go()
    },
    updateCurrentPromotionObj (callback) {
      this.currentPromotion = _cloneDeep(this.currentPromotion)
      callback()
    },
    async archivePromotion () {
      if (confirm('Confirm archiving promotion: "' + this.currentPromotion.customName + '"')) {
        try {
          this.archiveInProgress = true
          await archivePromotion({ id: this.currentPromotion.id })
          this.archiveInProgress = false
          this.$store.commit(C_ADD_SITE_ALERT, {
            message: `Promotion archived`,
            type: 'success',
            transient: true
          })
          this.$router.push({ name: 'promotions' })
        } catch (err) {
          this.archiveInProgress = false
          if (err && !err.error) {
            this.$store.commit(C_ADD_SITE_ALERT, {
              message: `Error in archiving promotion: ${err && err.message ? err.message : JSON.stringify(err)}`,
              type: 'error'
            })
          }
        }
      }
    },
    async publishPromotion () {
      if (!this.currentPromotion.budget || this.currentPromotion.budget === '0') {
        return alert('Please enter a budget amount')
      }
      if (this.validForm()) {
        if (confirm('Confirm publishing this promotion')) {
          try {
            this.publishInProgress = true
            this.currentPromotion = await publishPromotion({ id: this.currentPromotion.id })
            this.setCurrentPromotion()
            this.publishInProgress = false
            this.$store.commit(C_ADD_SITE_ALERT, {
              message: `Promotion has been published`,
              type: 'success',
              transient: true
            })
          } catch (err) {
            this.publishInProgress = false
            if (err && !err.error) {
              this.$store.commit(C_ADD_SITE_ALERT, {
                message: `Error in publishing scripts: ${err && err.message ? err.message : JSON.stringify(err)}`,
                type: 'error'
              })
            }
          }
        }
      } else {
        alert('Please check all required fields')
      }
    },
    async unpublishPromotion () {
      if (confirm('Confirm un-publishing this promotion')) {
        try {
          this.publishInProgress = true
          this.currentPromotion = await unPublishPromotion({ id: this.currentPromotion.id })
          this.setCurrentPromotion()
          this.publishInProgress = false
          this.$store.commit(C_ADD_SITE_ALERT, {
            message: `Promotion has been un-published`,
            type: 'success',
            transient: true
          })
        } catch (err) {
          this.publishInProgress = false
          if (err && !err.error) {
            this.$store.commit(C_ADD_SITE_ALERT, {
              message: `Error in publishing scripts: ${err && err.message ? err.message : JSON.stringify(err)}`,
              type: 'error'
            })
          }
        }
      }
    },
    addPhoneToContacts () {
      if (this.phoneContactToAdd) {
        if (!this.currentPromotion.draftMetadata.warnContactsIfOverBudget) {
          this.currentPromotion.draftMetadata.warnContactsIfOverBudget = []
        }
        if (this.currentPromotion.draftMetadata.warnContactsIfOverBudget.includes(this.phoneContactToAdd)) {
          return alert('Cannot add a phone number more than once')
        }
        this.currentPromotion.draftMetadata.warnContactsIfOverBudget.push(this.phoneContactToAdd)
        this.addPhoneContactModal = false
      } else {
        alert('Please enter a valid phone number')
      }
    },
    resetTimezone () {
      if (confirm('Confirm setting the timezone to your local (browser) timezone')) {
        this.currentPromotion.draftMetadata.timezone = this.localTimezone
      }
    },
    isDataEdited () {
      return !_isEqual(this.currentPromotion, this.currentPromotionOriginal)
    },
    async loadPromotionScripts () {
      try {
        this.scriptsInProgress = true
        const results = await getPromotionScripts()
        if (results && results.length) {
          this.promotionScripts = results.map(o => {
            return {
              text: o.value,
              value: o.value,
              metadata: o.metadata,
              description: o.description
            }
          })
        }
        this.scriptsInProgress = false
      } catch (err) {
        this.scriptsInProgress = false
        if (err && !err.error) {
          this.$store.commit(C_ADD_SITE_ALERT, {
            message: `Error in loading scripts: ${err && err.message ? err.message : JSON.stringify(err)}`,
            type: 'error'
          })
        }
      }
    },
    setCurrentPromotion () {
      if (this.currentPromotion.id) {
        this.currentPromotion.budgetSpentLabel = formatCurrency(this.currentPromotion.budgetSpent)
      }
      if (this.currentPromotion.budget) {
        this.currentPromotion.budgetLabel = formatCurrency(this.currentPromotion.budget)
      }
      if (this.currentPromotion.budgetThreshold) {
        this.currentPromotion.budgetThresholdLabel = formatCurrency(this.currentPromotion.budgetThreshold)
      }
      if (!this.currentPromotion.draftMetadata.timezone) {
        this.currentPromotion.draftMetadata.timezone = moment.tz.guess()
      }
      if (this.currentPromotion.dateStart) {
        const momentObj = moment.utc(this.currentPromotion.dateStart).tz(this.currentPromotion.draftMetadata.timezone)
        this.currentPromotion.dateStartTimezoned = momentObj.format('Y-MM-DD')
        this.currentPromotion.dateStartTime = moment.utc(momentObj.format('hh:mma'), 'hh:mma').toDate()
      }
      if (this.currentPromotion.dateEnd) {
        const momentObj = moment.utc(this.currentPromotion.dateEnd).tz(this.currentPromotion.draftMetadata.timezone)
        this.currentPromotion.dateEndTimezoned = momentObj.format('Y-MM-DD')
        this.currentPromotion.dateEndTime = moment.utc(momentObj.format('hh:mma'), 'hh:mma').toDate()
      }

      this.currentPromotionOriginal = _cloneDeep(this.currentPromotion)
      if (_cachedComponentData) {
        this.currentPromotion = _cachedComponentData
      }

      this.setCurrentScript()
    },
    async loadPromotion (promotionId) {
      try {
        this.apiInProgress = true
        this.currentPromotion = await getPromotion({ id: promotionId })
        this.setCurrentPromotion()
        this.apiInProgress = false
      } catch (err) {
        this.apiInProgress = false
        if (!this.currentPromotion) {
          this.currentPromotionError = `Error: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`
        } else {
          if (err && !err.error) {
            this.$store.commit(C_ADD_SITE_ALERT, {
              message: `Error in loading promotion: ${err && err.message ? err.message : JSON.stringify(err)}`,
              type: 'error'
            })
          }
        }
      }
    },
    setCurrentScript () {
      if (this.currentPromotion && this.currentPromotion.fileName && this.promotionScripts && this.promotionScripts.length) {
        this.currentScript = _cloneDeep(this.promotionScripts.find(o => o.value === this.currentPromotion.fileName))
        if (Object.keys(this.currentPromotion.draftMetadata.custom || {}).length) {
          this.currentScript.metadata.custom = Object.assign(this.currentScript.metadata.custom, this.currentPromotion.draftMetadata.custom)
        }
      } else {
        this.currentScript = null
      }
    },
    validForm () {
      return this.$refs && this.$refs.form && this.$refs.form.validate()
    },
    convertCustomPropToLabel (prop) {
      return _startCase(prop)
    },
    formatCustomPropDate (date) {
      return date ? formatDate(date) : ''
    },
    formatCustomPropToWeekday (dayNum) {
      return moment().day(dayNum).format('dddd')
    },
    formatCustomPropToTimeRange (start, end) {
      return moment.utc(start, 'HH:mm').format('hh:mma') + ' - ' + moment.utc(end, 'HH:mm').format('hh:mma')
    },
    getCustomOptionType (options, selectedOption) {
      const option = options.find(o => o.value === selectedOption)
      if (option) {
        return option.type
      }
    },
    getCustomOptionValueLabel (options, selectedOption) {
      const option = options.find(o => o.value === selectedOption)
      if (option) {
        return '"' + option.description.substring(0, 15) + (option.description.length > 15 ? '...' : '') + '" value'
      }
      return 'Condition value'
    },
    isCustomOptionRequired (options, selectedOption) {
      const option = options.find(o => o.value === selectedOption)
      if (option) {
        return option.required
      }
    },
    loadDayTimeRangeModal (dayTimeRange, index) {
      this.dayTimeRangeModal = true
      this.currentDayTimeRange = _cloneDeep(dayTimeRange)
      this.currentDayTimeRangeIndex = index
    },
    addUpdateDayTimeRange () {
      const dayTimeRange = { ...this.currentDayTimeRange }
      const foundDayTimeRange = this.currentPromotion?.draftMetadata?.custom?.daysAndTimesOfDay?.value?.find(o => areDayTimeRangesEqual.call(this, o, this.currentDayTimeRange))

      if (foundDayTimeRange) {
        return alert('Day and time ranges already exist')
      }

      if (this.currentDayTimeRangeIndex || this.currentDayTimeRangeIndex === 0) {
        this.currentPromotion.draftMetadata.custom.daysAndTimesOfDay.value[this.currentDayTimeRangeIndex] = dayTimeRange
      } else {
        if (this.currentPromotion?.draftMetadata?.custom?.daysAndTimesOfDay?.value) {
          this.currentPromotion.draftMetadata.custom.daysAndTimesOfDay.value.push(dayTimeRange)
        } else {
          this.currentPromotion.draftMetadata = {
            ...this.currentPromotion.draftMetadata,
            custom: {
              daysAndTimesOfDay: {
                type: 'dayTimeRanges',
                value: [dayTimeRange]
              }
            }
          }
        }
      }

      if (Object.keys(this.currentPromotion.draftMetadata.custom || {}).length) {
        this.currentScript.metadata.custom = Object.assign(this.currentScript.metadata.custom, this.currentPromotion.draftMetadata.custom)
      }

      this.closeDayTimeRangeModal()
    },
    deleteDayTimeEntry () {
      if (this.currentDayTimeRangeIndex || this.currentDayTimeRangeIndex === 0) {
        this.currentPromotion.draftMetadata.custom.daysAndTimesOfDay.value.splice(this.currentDayTimeRangeIndex, 1)
        this.currentPromotion.draftMetadata.custom.daysAndTimesOfDay.value = [].concat(this.currentPromotion.draftMetadata.custom.daysAndTimesOfDay.value)
      }

      if (Object.keys(this.currentPromotion.draftMetadata.custom || {}).length) {
        this.currentScript.metadata.custom = Object.assign(this.currentScript.metadata.custom, this.currentPromotion.draftMetadata.custom)
      }

      this.closeDayTimeRangeModal()
    },
    addDayEntry () {
      if (
        (this.currentDaySet || this.currentDaySet === 0) &&
        !this.currentDayTimeRange.days.find((o) => o === this.currentDaySet)
      ) {
        this.currentDayTimeRange.days.push(this.currentDaySet)
        this.currentDaySet = null
      }
    },
    addTimeEntry () {
      if (this.currentTimeSet.start && this.currentTimeSet.end && this.currentTimeSet.start.getTime() >= this.currentTimeSet.end.getTime()) {
        return alert('Please check the time range (range must start before it ends)')
      }

      const start = this.currentTimeSet.start ? moment.utc(this.currentTimeSet.start).format('HH:mm') : null
      const end = this.currentTimeSet.end ? moment.utc(this.currentTimeSet.end).format('HH:mm') : null

      if (start && end &&
        !this.currentDayTimeRange.times.find((o) => o.start === start && o.end === end)
      ) {
        this.currentDayTimeRange.times.push({ start, end })
      }
    },
    onDayTimeItemDelete (type, index) {
      if (type === 'days') {
        this.currentDayTimeRange.days.splice(index, 1)
      } else if (type === 'times') {
        this.currentDayTimeRange.times.splice(index, 1)
      }
    },
    closeDayTimeRangeModal () {
      // this.currentTimeSet = { start: null, end: null }
      this.currentDaySet = null
      this.currentDayTimeRange = null
      this.dayTimeRangeModal = false
    },
    async save () {
      if (this.validForm()) {
        try {
          if (this.currentPromotion.dateStartTimezoned) {
            this.currentPromotion.dateStart = timezonedMoment({
              dateTime: moment.utc(this.currentPromotion.dateStartTimezoned).format('Y-MM-DD') + ' ' + (this.currentPromotion.dateStartTime ? moment.utc(this.currentPromotion.dateStartTime).format('hh:mma') : '12:00am'),
              format: 'Y-MM-DD hh:mma',
              timezone: this.currentPromotion.draftMetadata.timezone
            }).toDate()
          }
          if (this.currentPromotion.dateEndTimezoned) {
            this.currentPromotion.dateEnd = timezonedMoment({
              dateTime: moment.utc(this.currentPromotion.dateEndTimezoned).format('Y-MM-DD') + ' ' + (this.currentPromotion.dateEndTime ? moment.utc(this.currentPromotion.dateEndTime).format('hh:mma') : '11:59pm'),
              format: 'Y-MM-DD hh:mma',
              timezone: this.currentPromotion.draftMetadata.timezone
            }).toDate()
          }
          if (this.currentScript) {
            this.currentPromotion.draftMetadata.custom = this.currentScript.metadata.custom
          }

          let currentPromotion
          this.apiInProgress = true
          if (this.currentPromotion.id) {
            currentPromotion = await updatePromotion({
              id: this.currentPromotion.id,
              scriptName: this.currentPromotion.fileName,
              customName: this.currentPromotion.customName,
              draftMetadata: this.currentPromotion.draftMetadata,
              dateStart: this.currentPromotion.dateStart,
              dateEnd: this.currentPromotion.dateEnd
            })
            this.$store.commit(C_ADD_SITE_ALERT, {
              message: `Promotion updated`,
              type: 'success',
              transient: true
            })
          } else {
            currentPromotion = await createPromotion({
              scriptName: this.currentPromotion.fileName,
              customName: this.currentPromotion.customName,
              draftMetadata: this.currentPromotion.draftMetadata,
              dateStart: this.currentPromotion.dateStart,
              dateEnd: this.currentPromotion.dateEnd
            })
            this.$router.replace({ name: 'promotions-promotion', params: { promotionId: currentPromotion.id } })
            this.$store.commit(C_ADD_SITE_ALERT, {
              message: `Promotion created`,
              type: 'success',
              transient: true
            })
          }

          if (((this.currentPromotion.budgetLabel && this.currentPromotion.budgetLabel !== this.currentPromotionOriginal.budgetLabel) ||
            (this.currentPromotion.budgetThresholdLabel && this.currentPromotion.budgetThresholdLabel !== this.currentPromotionOriginal.budgetThresholdLabel)) &&
            this.isSuperAdmin) {
            currentPromotion = await updatePromotionBudget({ id: this.currentPromotion.id, budget: this.currentPromotion.budgetLabel, budgetThreshold: this.currentPromotion.budgetThresholdLabel })
            this.$store.commit(C_ADD_SITE_ALERT, {
              message: `Promotion budget updated`,
              type: 'success',
              transient: true
            })
          }
          this.currentPromotion = currentPromotion
          this.setCurrentPromotion()
          this.apiInProgress = false
        } catch (err) {
          this.apiInProgress = false
          if (err && !err.error) {
            this.$store.commit(C_ADD_SITE_ALERT, {
              message: `Error in saving promotion: ${err && err.message ? err.message : JSON.stringify(err)}`,
              type: 'error'
            })
          }
        }
      } else {
        alert('Please check all required fields')
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!this.isDataEdited() || confirm('Discard changes made?')) {
      _cachedComponentData = null
      next()
    } else {
      _cachedComponentData = this.currentPromotion
      next(false)
    }
  }
}

function areDayTimeRangesEqual (range1, range2) {
  if (!range1 || !range2) return false

  // Compare days array
  const daysEqual = Array.isArray(range1.days) && Array.isArray(range2.days) &&
      range1.days.length === range2.days.length &&
      range1.days.every((day, index) => day === range2.days[index])

  // Compare times array
  const timesEqual = Array.isArray(range1.times) && Array.isArray(range2.times) &&
      range1.times.length === range2.times.length &&
      range1.times.every((time, index) => time.start === range2.times[index].start && time.end === range2.times[index].end)

  return daysEqual && timesEqual
}
